import { Suspense, lazy, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCredential,
  GenerateGuestId,
  CheckTokenExpiry,
  GetCartItem,
} from "./store/slices/credential-slice";
import { setAddress } from "./store/slices/address-slice";
import { GetMenu, setMenu } from "./store/slices/menu-slice";
import { addAllToCart, deleteAllFromCart } from "./store/slices/cart-slice";
import {
  addAllToWishlist,
  deleteAllFromWishlist,
  GetWishlistItems,
} from "./store/slices/wishlist-slice";
import { getContents, setContents } from "./store/slices/widget-slice";
import { setCurrency } from "./store/slices/currency-slice";

const Home = lazy(() => import("./pages/Home"));

const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const Contact = lazy(() => import("./pages/other/Contact"));
const About = lazy(() => import("./pages/other/About"));
const EmailConfirmation = lazy(() => import("./pages/other/EmailConfirmation"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const Unsubscribe = lazy(() => import("./pages/other/Unsubscribe"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmation = lazy(() => import("./pages/other/OrderConfirmation"));
const Collection = lazy(() => import("./pages/shop/ProductCollection"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Support = lazy(() => import("./pages/other/Support"));

function App() {
  const isLogged = useSelector((state) => state.credential.isLogged);
  const guestId = useSelector((state) => state.credential.guestId);
  const customerId = useSelector((state) => state.credential.customerId);
  const customerToken = useSelector((state) => state.credential.customerToken);
  const currencyId = useSelector((state) => state.currency.currencyId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const offset = new Date().getTimezoneOffset();
    let result;
    let promise;
    let currency = currencyId;

    if (!currencyId) {
      dispatch(
        setCurrency({
          currencyId: 1,
          currencySymbol: "AED",
        })
      );
      currency = 1;
    }

    if (!isLogged && (guestId === "" || guestId === undefined)) {
      result = dispatch(GenerateGuestId());
      dispatch(
        setCredential({
          isLogged: false,
          guestId: result,
          customerId: 0,
          customerToken: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          purchasedProducts: [],
        })
      );

      dispatch(deleteAllFromCart());
      dispatch(deleteAllFromWishlist());
    } else if (!isLogged && guestId !== "" && guestId !== undefined) {
      dispatch(
        setCredential({
          isLogged: false,
          guestId: guestId,
          customerId: 0,
          customerToken: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          purchasedProducts: [],
        })
      );
    } else if (isLogged && customerId !== 0) {
      result = dispatch(CheckTokenExpiry(customerToken, customerId));
      promise = Promise.resolve(result);

      promise.then((value) => {
        if (value.Code === 0) {
          dispatch(
            setCredential({
              isLogged: true,
              guestId: "",
              customerId: value.Message.CustomerId,
              customerToken: value.Message.CustomerToken,
              firstName: value.Message.FirstName,
              lastName: value.Message.LastName,
              phoneNumber: value.Message.PhoneNumber,
              email: value.Message.Email,
              purchasedProducts: value.Message.PurchasedProducts,
            })
          );
          dispatch(
            setAddress({
              addressBookId: value.Message.AddressBook.AddressBookId
                ? value.Message.AddressBook.AddressBookId
                : 0,
              firstName: value.Message.AddressBook.FirstName
                ? value.Message.AddressBook.FirstName
                : "",
              lastName: value.Message.AddressBook.LastName
                ? value.Message.AddressBook.LastName
                : "",
              phoneNumber: value.Message.AddressBook.PhoneNumber
                ? value.Message.AddressBook.PhoneNumber
                : "",
              countryCode: value.Message.AddressBook.CountryCode
                ? value.Message.AddressBook.CountryCode
                : "",
              cityId: value.Message.AddressBook.CityId
                ? value.Message.AddressBook.CityId
                : 0,
              postalCode: value.Message.AddressBook.PostalCode
                ? value.Message.AddressBook.PostalCode
                : 0,
              address: value.Message.AddressBook.Address1
                ? value.Message.AddressBook.Address1
                : "",
            })
          );

          //cart
          result = dispatch(
            GetCartItem(value.Message.CustomerId, "", offset, currency)
          );
          promise = Promise.resolve(result);
          promise.then((value) => {
            dispatch(addAllToCart(value.Message));
          });

          //wishlist
          result = dispatch(
            GetWishlistItems(value.Message.CustomerId, "", offset, currency)
          );
          promise = Promise.resolve(result);
          promise.then((value) => {
            dispatch(addAllToWishlist(value.Message));
          });
        } else {
          result = dispatch(GenerateGuestId());
          dispatch(
            setCredential({
              isLogged: false,
              guestId: result,
              customerId: 0,
              customerToken: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              purchasedProducts: [],
            })
          );

          dispatch(deleteAllFromCart());
          dispatch(deleteAllFromWishlist());
          navigate("/");
        }
      });
    }

    //cart
    result = dispatch(GetCartItem(customerId, guestId, offset, currency));
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(addAllToCart(value.Message));
    });

    //wishlist
    result = dispatch(GetWishlistItems(customerId, guestId, offset, currency));
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(addAllToWishlist(value.Message));
    });

    //menu
    result = dispatch(GetMenu());
    promise = Promise.resolve(result);
    promise.then((value) => {
      if (value) {
        let newArray = [...value];
        let isFound = false;
        let object = {};
        newArray.forEach((parent) => {
          parent.Sub.forEach((sub) => {
            sub.Sub.forEach((subsub, index) => {
              if (subsub.CategoryId === 2) {
                isFound = true;
                object = subsub;
                sub.Sub.splice(index, 1);
              }
            });

            if (isFound) {
              sub.Sub.unshift(object);
            }

            isFound = false;
            object = {};
          });
        });
        dispatch(setMenu(newArray));
      }
      dispatch(setMenu({ menu: value }));
    });

    //content
    result = dispatch(getContents());
    promise = Promise.resolve(result);
    promise.then((value) => {
      dispatch(setContents(value));
    });
  }, [
    isLogged,
    dispatch,
    guestId,
    customerId,
    navigate,
    customerToken,
    currencyId,
  ]);

  return (
    <ScrollToTop>
      <Suspense
        fallback={
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
          <Route
            path={process.env.PUBLIC_URL + "/product/:id"}
            element={<ProductTabLeft />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/category/:category/:id"}
            element={<Collection />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/brand/:brand/:id"}
            element={<Collection />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/search/:search"}
            element={<Collection />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/login-register"}
            element={<LoginRegister />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/email-confirm/:email/:code"}
            element={<EmailConfirmation />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/contact-us"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/about-us"}
            element={<About />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/wishlist"}
            element={<Wishlist />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/my-account"}
            element={<MyAccount />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/forgot-password"}
            element={<ForgotPassword />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/reset-password/:email/:code"}
            element={<ResetPassword />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/unsubscribe/:email"}
            element={<Unsubscribe />}
          />
          <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
          <Route
            path={process.env.PUBLIC_URL + "/checkout"}
            element={<Checkout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/page/:page/:id"}
            element={<Support />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/order-success/:orderNumber"}
            element={<OrderConfirmation />}
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
}

export default App;
